.wui-card-more {

  .ant-card-head-wrapper {
    flex-direction: row;
  }

  .ant-card-body {
    padding: 0px;
  }
}

@primary-color: #096DD9;
.loading-screen-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    width: 140px;
    height: 140px;
    position: absolute;
    margin: auto;
  }

  .logo {
    width: 70px;
    height: 70px;
  }
}

@primary-color: #096DD9;
.content {
  text-align: center;

  .children-section {
    text-align: left;
    margin-bottom: 20px;
  }

  .download-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 30px;
  }
}
@primary-color: #096DD9;
@media print {
  @page {
    margin-top: 1cm;
    margin-bottom: 1cm;
  }
}

#stock-opname-product-export-wrapper {
  line-height: 2;
  .container {
    padding: 0 1cm;
    position: relative;
    height: 100%;
    .document-title {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 40px;
    }

    .result-list {
      display: flex;
      align-items: center;
      gap: 24px;
      .result-list-keys {
        margin-right: 16px;
      }
    }

    .stock-opname-result {
      margin-bottom: 36px;
      padding-left: 60px;
      padding-right: 60px
    }

    .stock-opname-result-detail {
      margin-bottom: 32px;
      .result-list .result-list-values .result-list-value span {
        width: 200px;
      }
    }

    .footer-desc {
      margin-bottom: 32px;
    }

    .sign-list {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-top: 280px;
      .sign-item {
        display: flex;
        flex-direction: column;
        gap: 80px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .table-list {
    .table-item {
      margin-bottom: 28px;
      table tbody tr{
        page-break-inside: avoid;
      }
    }

    .table-title {
      margin-bottom: 8px;
    }

    table {
      width: 100%;
      td, th {
        border: 1px solid #000;
        padding: 8px;
      }
    }
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}

@primary-color: #096DD9;
.wui-statistic-card {

  .info-wrapper {

    .value {
      font-size: 24px;
      margin-bottom: 5px;
      line-height: normal;
    }

    .label {
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 5px;
      display: inline-block;
      font-size: 13px;
      letter-spacing: 0.5px;
    }

    .subtitle {
      margin-bottom: 0px;
      color: rgb(131, 146, 165);
    }
  }
}

@primary-color: #096DD9;
.wui-section-title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;

  &.no-title {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;

    .right-section {
      width: 100%;

      .btn-wrapper {
        display: grid;
        grid-template-columns: 1fr;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}

@primary-color: #096DD9;